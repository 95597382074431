import type { RefObject } from 'react';

import type { BREADCRUMBS_MODES } from '@confluence/breadcrumbs';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import { useIsEditorToolbarDockedAtTop } from './useIsEditorToolbarDockedAtTop';
import { useIsTitleIntersectingWithHeader } from './useIsTitleIntersectingWithHeader';
import { useBreadcrumbsDisplayMode } from './useBreadcrumbsDisplayMode';

type UseProgressiveHeaderType = {
	contentType: string;
	contentMode: string;
	isSpaceOverviewPage: boolean;
	isVersionHistoryPage: boolean;
	isArchived: boolean;
	isObjectSidebarShown: boolean;
	contentTypesHeaderDataLoading: boolean;
	objectHeaderRef: RefObject<HTMLDivElement | null>;
	contentId: string;
};

export const useProgressiveHeader = ({
	contentType,
	contentMode,
	isSpaceOverviewPage,
	isVersionHistoryPage,
	isArchived,
	isObjectSidebarShown,
	contentTypesHeaderDataLoading,
	objectHeaderRef,
	contentId,
}: UseProgressiveHeaderType): {
	breadcrumbsDisplayMode: BREADCRUMBS_MODES;
	isBorderlessHeader: boolean;
} => {
	const [{ isViewMode: isLiveViewMode, isEditMode: isLiveEditMode }] = useLivePageMode();
	const isEditorToolbarDockedAtTop = useIsEditorToolbarDockedAtTop();

	const isPageOrBlog = contentType === 'page' || contentType === 'blogpost';
	const isViewOrViewLiveMode = contentMode === 'view' || isLiveViewMode;
	const isInEditor = contentMode === 'draft' || contentMode === 'edit' || isLiveEditMode;
	const isEditorToolbarShown = isInEditor && isEditorToolbarDockedAtTop;

	const shouldUseProgressiveHeader =
		(isPageOrBlog && isViewOrViewLiveMode) || (isInEditor && !isEditorToolbarShown);

	const isTitleIntersectingWithHeader = useIsTitleIntersectingWithHeader({
		contentId,
		objectHeaderRef,
		shouldUseProgressiveHeader,
	});

	const breadcrumbsDisplayMode = useBreadcrumbsDisplayMode({
		isVersionHistoryPage,
		contentType,
		isSpaceOverviewPage,
		isArchived,
		isEditorToolbarShown,
		isTitleFullyVisible: !isTitleIntersectingWithHeader,
		isObjectSidebarShown,
	});

	// Don't show the border when data is still loading to prevent flickering of the header between pages
	const isBorderlessHeader =
		contentTypesHeaderDataLoading ||
		(!isSpaceOverviewPage &&
			!isTitleIntersectingWithHeader &&
			!isObjectSidebarShown &&
			!isEditorToolbarShown &&
			shouldUseProgressiveHeader);

	return {
		breadcrumbsDisplayMode,
		isBorderlessHeader,
	};
};
