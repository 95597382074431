import { fg } from '@confluence/feature-gating';

/** This file identifies if certain actions should be rendered for the respective content type/mode
 * Each action has additional checks on top of what is listed here based on the permissions (via webItems), or by the parent (via allowedFeatures) at content-types-header/src/content-types-actions/MoreActions.tsx
 */

type ContentType = string | null | undefined;

export const getIsConvertRendered = (
	contentType: ContentType,
	contentMode?: string,
	isSpaceOverviewPage?: boolean,
) => {
	if (contentType === 'page' && !isSpaceOverviewPage) {
		return contentMode === 'view';
	}
	return false;
};

export const getIsCopyRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return contentMode === 'live' || contentMode === 'view';
	}
	return contentType === 'whiteboard' || contentType === 'database' || contentType === 'embed';
};

export const getIsArchiveRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return contentMode === 'live' || contentMode === 'view' || contentMode === 'edit';
	}
	return contentType === 'whiteboard' || contentType === 'database' || contentType === 'embed';
};

export const getIsExportRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return contentMode === 'live' || contentMode === 'view' || contentMode === 'archived';
	} else if (contentType === 'blogpost') {
		return contentMode === 'view';
	}
	return contentType === 'whiteboard' || contentType === 'database';
};

export const getIsMoveRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return (
			contentMode === 'live' ||
			contentMode === 'view' ||
			contentMode === 'edit' ||
			contentMode === 'draft'
		);
	} else if (contentType === 'blogpost') {
		return contentMode === 'view' || contentMode === 'edit' || contentMode === 'draft';
	}
	return contentType === 'whiteboard' || contentType === 'database' || contentType === 'embed';
};

export const getIsTemplatesRendered = (
	contentType: ContentType,
	contentMode?: string,
	readOnly?: boolean,
) => {
	if (contentType === 'page') {
		return contentMode === 'live' || contentMode === 'draft';
	}
	return contentType === 'database' && contentMode !== 'archived' && !readOnly;
};

export const getIsPresentRendered = (
	contentType: ContentType,
	contentMode?: string,
	isSpaceOverviewPage?: boolean,
) => {
	return (
		(contentType === 'page' || contentType === 'blogpost') &&
		(contentMode === 'view' || (contentMode === 'live' && fg('live_docs_presenter_mode'))) &&
		!isSpaceOverviewPage
	);
};

export const getIsSwitchToLiveEditRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return contentMode === 'edit' || contentMode === 'draft';
	}
	return false;
};

export const getIsViewChangesRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return contentMode === 'live' || contentMode === 'edit';
	} else if (contentType === 'blogpost') {
		return contentMode === 'edit';
	}
	return false;
};

export const getIsUndoRedoFindRendered = (
	contentType: ContentType,
	contentMode?: string,
	hasEditPermission?: boolean,
	livePageMode?: string,
) => {
	if (contentType === 'page' || contentType === 'blogpost') {
		if (!hasEditPermission) {
			return false;
		}
		if (contentType === 'page' && contentMode === 'live' && livePageMode === 'view') {
			return false;
		}
		return contentMode === 'live' || contentMode === 'edit' || contentMode === 'draft';
	}
	return false;
};

export const getIsRevertRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page' || contentType === 'blogpost') {
		return contentMode === 'edit';
	}
	return false;
};

export const getIsAutomationsRendered = (
	contentType: ContentType,
	contentMode?: string,
	isSpaceOverviewPage?: boolean,
) => {
	if (contentType === 'page' && !isSpaceOverviewPage) {
		return contentMode === 'view' || contentMode === 'live' || contentMode === 'archived';
	}
	return false;
};

export const getIsAdvancedRendered = (
	contentType: ContentType,
	contentMode: string | undefined,
) => {
	return (
		(contentType === 'page' || contentType === 'blogpost') &&
		contentMode !== 'draft' &&
		contentMode !== 'edit'
	);
};

export const getIsAppsRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page' || contentType === 'blogpost') {
		return contentMode === 'live' || contentMode === 'view';
	}
	return false;
};

export const getIsFeedbackRendered = (contentType: ContentType) => {
	return contentType === 'whiteboard' || contentType === 'database' || contentType === 'embed';
};

export const getIsHelpRendered = (contentType: ContentType) => {
	return contentType === 'whiteboard' || contentType === 'database';
};

export const getIsContentOwnershipRendered = (contentType: ContentType) => {
	return (
		contentType === 'whiteboard' ||
		(contentType === 'database' && !fg('confluence_frontend_databases_content_wrapper')) ||
		contentType === 'embed'
	);
};

export const getIsClassifyContentRendered = (contentType: ContentType) => {
	if (contentType === 'page' || contentType === 'blogpost') {
		// Classify content has moved to the details panel for all page & blogpost types
		return false;
	}
	return (
		contentType === 'whiteboard' ||
		(contentType === 'database' && !fg('confluence_frontend_databases_content_wrapper'))
	);
};

export const getIsWatchRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page' || contentType === 'blogpost') {
		return contentMode !== 'draft';
	}

	return (
		contentType === 'whiteboard' ||
		(contentType === 'database' && fg('confluence_frontend_database_inline_comments'))
	);
};

export const getIsConvertToPageRendered = (contentType: ContentType, contentMode?: string) => {
	return contentType === 'page' && contentMode === 'live';
};

export const getIsPreviewRendered = (contentType: ContentType, contentMode?: string) => {
	return (
		(contentType === 'page' || contentType === 'blogpost') &&
		(contentMode === 'edit' || contentMode === 'draft')
	);
};

export const getIsPageHistoryRendered = (contentType: ContentType, contentMode?: string) => {
	if (contentType === 'page') {
		return contentMode === 'live' || contentMode === 'view' || contentMode === 'archived';
	} else if (contentType === 'blogpost') {
		return contentMode === 'view';
	}
	return false;
};
