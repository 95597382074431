import { usePageLayoutResize } from '@atlaskit/page-layout';

import { BREADCRUMBS_MODES } from '@confluence/breadcrumbs';
import { getIsSideNavCollapsed } from '@confluence/page-layout/entry-points/getIsSideNavCollapsed';

type UseBreadcrumbsDisplayModeType = {
	contentType: string;
	isSpaceOverviewPage: boolean;
	isVersionHistoryPage: boolean;
	isArchived: boolean;
	isEditorToolbarShown: boolean;
	isTitleFullyVisible?: boolean;
	isObjectSidebarShown: boolean;
};

export const useBreadcrumbsDisplayMode = ({
	contentType,
	isSpaceOverviewPage,
	isVersionHistoryPage,
	isArchived,
	isEditorToolbarShown,
	isTitleFullyVisible,
	isObjectSidebarShown,
}: UseBreadcrumbsDisplayModeType): BREADCRUMBS_MODES => {
	const { isLeftSidebarCollapsed: isNav3LeftSidebarCollapsed } = usePageLayoutResize();
	const isNav4LeftSideNavCollapsed = getIsSideNavCollapsed();
	const isLeftSideNavCollapsed = isNav4LeftSideNavCollapsed || isNav3LeftSidebarCollapsed;

	if (!isVersionHistoryPage) {
		// all live pages, page, blogs, and editor except for space overview and archived pages
		if (
			(contentType === 'blogpost' || contentType === 'page') &&
			!isSpaceOverviewPage &&
			!isArchived
		) {
			if (isEditorToolbarShown) {
				// don't care about title visibility
				if (isLeftSideNavCollapsed) {
					return BREADCRUMBS_MODES.FULL;
				} else {
					return BREADCRUMBS_MODES.ONLY_CONTENT_TITLE;
				}
			} else {
				// care about title visibility (aka view page or blogpost, live page view mode)
				if (isLeftSideNavCollapsed) {
					if (isTitleFullyVisible) {
						return BREADCRUMBS_MODES.ONLY_SPACE_NAME;
					} else {
						return BREADCRUMBS_MODES.FULL;
					}
				} else {
					if (isTitleFullyVisible) {
						if (isObjectSidebarShown) {
							// in the case where you are at the top of the page and the object sidebar is open, we should force show breadcrumbs and border so the sidebar isn't floating there by itself
							return BREADCRUMBS_MODES.ONLY_CONTENT_TITLE;
						}
						return BREADCRUMBS_MODES.HIDDEN;
					} else {
						return BREADCRUMBS_MODES.ONLY_CONTENT_TITLE;
					}
				}
			}
			// all other content types
		} else if (
			contentType === 'database' ||
			contentType === 'whiteboard' ||
			contentType === 'embed'
		) {
			if (isLeftSideNavCollapsed) {
				return BREADCRUMBS_MODES.FULL;
			} else {
				return BREADCRUMBS_MODES.ONLY_CONTENT_TITLE;
			}
		}
	}

	return BREADCRUMBS_MODES.FULL;
};
